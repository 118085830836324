import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { getImageSrcForBasketView } from '../../helpers/image'
import { LocalizedBasketLine } from '../../types/generated/api.interface'
import TrashIcon from '../../icons/generated/TrashIcon'
import { translate } from '../../helpers/translate'
import { QuantityControl } from '../QuantityControl/QuantityControl'
import { useBasket } from '../../modules/basket'
import { routes } from '../Router/routes'
import { Link } from 'gatsby'

type Props = {
  data: LocalizedBasketLine
  onRemove: (productId: string) => void
  withQuantity?: boolean
  readonly?: boolean
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',

    '& a': {
      color: 'inherit',
    },
  },
  info: {
    width: '100%',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1.5),
  },
  removeButton: {
    padding: 0,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}))

export const BasketLineProductInfo = ({
  data,
  onRemove,
  readonly,
  withQuantity,
}: Props): ReactElement => {
  const classes = useStyles()
  const basket = useBasket()
  const {
    variantId: productId,
    name: productName,
    brand,
    imageUrl,
    amount,
    remainingStock,
    colorName,
  } = data
  if (!productId) {
    return <></>
  }

  const productLink = readonly
    ? ''
    : routes.productPage.build(productId, productName, colorName)

  const image = (
    <img
      height="126px"
      width="126px"
      {...getImageSrcForBasketView(imageUrl || '')}
      loading="lazy"
    />
  )

  return (
    <div className={classes.wrapper}>
      {productLink ? <Link to={productLink}>{image}</Link> : image}
      <div className={classes.info}>
        <Typography variant="overline" color="textSecondary" component="div">
          {productLink ? <Link to={productLink}>{brand}</Link> : brand}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          component={productLink ? Link : 'div'}
          to={productLink}
        >
          {productName}
        </Typography>
        <Box mt={0.5} mb={2.5}>
          <Typography variant="overline" color="textSecondary" component="div">
            {translate('basket.basketLineProductId', { productId })}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          {withQuantity &&
            (readonly ? (
              <Typography variant="body2">
                {translate('basket.basketLineAmount', {
                  amount: String(amount),
                })}
              </Typography>
            ) : (
              <QuantityControl
                min={1}
                max={remainingStock}
                quantity={amount || 0}
                onIncrease={() => basket.addToBasket(productId, 1)}
                onDecrease={() => basket.addToBasket(productId, -1)}
                onValueChange={amount => basket.setAmount(productId, amount)}
              />
            ))}
          {!readonly && (
            <Button
              className={classes.removeButton}
              variant="text"
              size="small"
              onClick={() => {
                onRemove(productId)
              }}
            >
              <TrashIcon fontSize="inherit" />
              {translate('basket.remove')}
            </Button>
          )}
        </Box>
      </div>
    </div>
  )
}
