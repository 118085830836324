import { Box, IconButton, makeStyles, Popover, Typography } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import { translate } from '../../helpers/translate';
import InfoIcon from '../../icons/generated/InfoIcon';

const useStyles = makeStyles((theme) => {
    return {
        text: {
            color: theme.palette.grey[500],
            whiteSpace: 'nowrap',
        },
        popover: {
            padding: theme.spacing(3),
            maxWidth: theme.spacing(55),
        },
    };
});

export const VATInfoPopover = (): ReactElement => {
    const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null);
    const styles = useStyles();
    const open = !!popoverAnchor;
    return (
        <Box display="flex" alignItems="center">
            <Typography className={styles.text} variant="caption" color="textSecondary" component="div">
                {translate('basket.vatNoticeHeadline')}
            </Typography>
            <IconButton size="small" onClick={(event) => setPopoverAnchor(event.currentTarget)}>
                <InfoIcon fontSize="inherit" />
            </IconButton>
            <Popover
                id="vat-info-popover"
                open={open}
                anchorEl={popoverAnchor}
                onClose={() => setPopoverAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={styles.popover}>
                    <Typography
                        variant="body1"
                        component="p"
                        dangerouslySetInnerHTML={{
                            __html: translate('basket.vatNoticeContent'),
                        }}
                    />
                </div>
            </Popover>
        </Box>
    );
};
