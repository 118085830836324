import {
  Box,
  Card,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React, { ReactElement } from 'react'
import { translate } from '../../helpers/translate'
import { useBasket } from '../../modules/basket'
import { BasketLineProductInfo } from '../BasketLineProductInfo/BasketLineProductInfo'
import { StockStatusIndicator } from '../StockStatusIndicator/StockStatusIndicator'
import { QuantityControl } from '../QuantityControl/QuantityControl'
import { LocalizedBasket } from '../../types/generated/api.interface'
import { VATInfoPopover } from '../VATInfoPopover/VATInfoPopover'

const useStyles = makeStyles<Theme, BasketProductListProps>(theme => {
  const borderColor = theme.palette.grey[100]
  return {
    desktopTable: {
      width: '100%',
      marginTop: theme.spacing(4),
      borderCollapse: 'separate',
      borderSpacing: `0 ${theme.spacing(1.875)}px`,
      '& th': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: 'left',
        '&:first-child': {
          paddingLeft: theme.spacing(3.75),
        },
        '&:last-child': {
          paddingRight: theme.spacing(4),
          textAlign: 'right',
        },
      },
      '& tbody': {
        '& tr': {
          borderRadius: '6px',
          boxShadow:
            '0px 1px 1px rgba(25, 34, 40, 0.1), 0px 0px 1px rgba(25, 34, 40, 0.05)',
        },
      },
      '& td': {
        verticalAlign: 'top',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1.5),
        backgroundColor: theme.palette.background.default,
        borderTop: `solid 1px ${borderColor}`,
        borderBottom: `solid 1px ${borderColor}`,
        '& > *': {
          borderCollapse: 'collapse',
          borderSpacing: '0 0',
        },
        '&:first-child': {
          paddingTop: theme.spacing(1.5),
          borderLeft: `solid 1px ${borderColor}`,
          borderRadius: '6px 0 0 6px',
          paddingLeft: theme.spacing(0.75),
          textAlign: 'left',
        },
        '&:last-child': {
          position: 'relative',
          borderRight: `solid 1px ${borderColor}`,
          borderRadius: '0 6px 6px 0',
          paddingRight: theme.spacing(4),
          textAlign: 'right',
        },
      },
    },
    desktopVatInfo: {
      position: 'absolute',
      right: theme.spacing(3),
      bottom: theme.spacing(3),
    },
    stockStatus: {
      paddingTop: theme.spacing(5),
    },
    quantity: {
      paddingTop: props =>
        props.readonly ? theme.spacing(4.75) : theme.spacing(4),
    },
    price: {
      paddingTop: theme.spacing(4.5),
      whiteSpace: 'nowrap',
    },
    mobileList: {
      marginTop: theme.spacing(4),
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(1.5),
      },
    },
    mobileProductCard: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(4.25),
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(0.75),
      '& > *': {
        width: '100%',
      },
    },
    mobileTable: {
      borderCollapse: 'separate',
      borderSpacing: `0 ${theme.spacing(1)}px`,
      marginTop: theme.spacing(0.5),
      '& th': {
        textAlign: 'left',
        paddingLeft: theme.spacing(3.25),
      },
      '& td': {
        textAlign: 'right',
      },
    },
    mobileVatInfo: {
      paddingLeft: theme.spacing(3.25),
    },
  }
})

type BasketProductListProps = {
  lines: LocalizedBasket['lines']
  readonly?: boolean
}

export const BasketProductList = (
  props: BasketProductListProps,
): ReactElement => {
  const { readonly, lines } = props
  const basket = useBasket()
  const classes = useStyles(props)
  const theme = useTheme()
  const isMediumScreenOrLarger = useMediaQuery(theme.breakpoints.up('md'))

  if (basket.initializing) {
    return <CircularProgress size={'1.6em'} />
  }

  return (
    <>
      {isMediumScreenOrLarger && (
        <table className={classes.desktopTable}>
          <thead>
            <tr>
              <Typography variant="caption" component="th">
                {translate('basket.basketLineHeaderProduct')}
              </Typography>
              <Typography variant="caption" component="th">
                {translate('basket.basketLineHeaderDelivery')}
              </Typography>
              <Typography variant="caption" component="th">
                {translate('basket.basketLineHeaderAmount')}
              </Typography>
              <Typography variant="caption" component="th">
                {translate('basket.basketLineHeaderPricePerUnit')}
              </Typography>
              <Typography variant="caption" component="th">
                {translate('basket.basketLineHeaderPriceTotal')}
              </Typography>
            </tr>
          </thead>
          <tbody>
            {lines?.map(line => {
              const {
                variantId = '',
                amount = 0,
                remainingStock = 0,
                stockStatus,
                stockText,
                formattedUnitPrice,
                formattedTotalPriceExVat,
              } = line
              if (!variantId) {
                return <></>
              }
              return (
                <tr key={variantId}>
                  <td>
                    <BasketLineProductInfo
                      data={line}
                      onRemove={productId => basket.removeFromBasket(productId)}
                      readonly={readonly}
                    />
                  </td>
                  <td className={classes.stockStatus}>
                    <StockStatusIndicator
                      color={stockStatus}
                      text={stockText}
                    />
                  </td>
                  <td className={classes.quantity}>
                    {readonly ? (
                      <Typography variant="body2">{amount}</Typography>
                    ) : (
                      <QuantityControl
                        min={1}
                        max={remainingStock}
                        quantity={amount || 0}
                        onIncrease={() => basket.addToBasket(variantId, 1)}
                        onDecrease={() => basket.addToBasket(variantId, -1)}
                        onValueChange={amount =>
                          basket.setAmount(variantId, amount)
                        }
                      />
                    )}
                  </td>
                  <Typography
                    className={classes.price}
                    variant="subtitle1"
                    component="td"
                  >
                    {formattedUnitPrice}
                  </Typography>
                  <td>
                    <Typography
                      className={classes.price}
                      variant="subtitle1"
                      component="div"
                    >
                      {formattedTotalPriceExVat}
                    </Typography>
                    {!line.vat && (
                      <div className={classes.desktopVatInfo}>
                        <VATInfoPopover />
                      </div>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {!isMediumScreenOrLarger && (
        <div className={classes.mobileList}>
          {lines?.map(line => {
            const {
              stockStatus,
              stockText,
              formattedUnitPrice,
              formattedTotalPriceExVat,
            } = line
            return (
              <Card key={line.variantId} className={classes.mobileProductCard}>
                <BasketLineProductInfo
                  data={line}
                  onRemove={productId => basket.removeFromBasket(productId)}
                  readonly={readonly}
                  withQuantity
                />
                <table className={classes.mobileTable}>
                  <tr>
                    <Typography component="th" variant="body2">
                      {translate('basket.basketLineHeaderDelivery')}
                    </Typography>
                    <td>
                      <Box display="flex" justifyContent="flex-end">
                        <StockStatusIndicator
                          color={stockStatus}
                          text={stockText}
                        />
                      </Box>
                    </td>
                  </tr>
                  <tr>
                    <Typography component="th" variant="body2">
                      {translate('basket.basketLineHeaderPricePerUnit')}
                    </Typography>
                    <Typography component="td" variant="subtitle2">
                      {formattedUnitPrice}
                    </Typography>
                  </tr>
                  <tr>
                    <Typography component="th" variant="body2">
                      {translate('basket.basketLineHeaderPriceTotal')}
                    </Typography>
                    <Typography component="td" variant="subtitle2">
                      {formattedTotalPriceExVat}
                    </Typography>
                  </tr>
                </table>
                {!line.vat && (
                  <div className={classes.mobileVatInfo}>
                    <VATInfoPopover />
                  </div>
                )}
              </Card>
            )
          })}
        </div>
      )}
    </>
  )
}
